






























































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useNotification } from '@/composition/notification';
import { useSettingFavorite } from '@/composition/favorites';
import { useConfirm } from '@/composition/confirm';
import FcColor from '@/components/FcColor.vue';
import FcImage from '@/components/FcImage.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import FcNumberForm from '@/components/FcNumberForm.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'FavoritesSetting',
  props: {
    favoriteItemStatusId: {
      type: String,
      default: '',
    },
  },
  components: {
    FcColor,
    FcImage,
    FcStaticImage,
    FcNumberForm,
    FcRoleLoading,
    FcRoleDeny,
  },
  setup(props, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('favorites'));
    const { confirmDialog } = useConfirm();

    const isSaving = ref(false);
    const setting = useSettingFavorite(props.favoriteItemStatusId);
    const notification = useNotification();

    const save = async () => {
      if (!(await confirmDialog('本当に保存しますか?'))) return;
      isSaving.value = true;
      try {
        if (props.favoriteItemStatusId) {
          await setting.saveFavoriteSetting();
        } else {
          await setting.createFavoriteSetting();
          context.root.$router.push('/favorites/settings');
        }
        notification.notify('保存しました');
      } catch (error) {
        notification.error(error);
      } finally {
        isSaving.value = false;
      }
    };

    return {
      pageTitle: 'ランク設定',
      myRoleSettings,
      setting,
      isSaving,
      save,
    };
  },
});
